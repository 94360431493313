import { getApiInvokeUrl, isDevDesk } from '../utils';

export enum DataLoadingStatus {
  SUCCESS,
  FAILED,
  LOADING,
}

export class callApiClass<T> {
  private dataLoadingStatus: DataLoadingStatus = DataLoadingStatus.LOADING;
  private errorMsg = '';
  private fetchResult: T;
  private fetchResultList: T[];
  private token: string | null = '';
  private isAuthenticated = false;

  constructor(token: string | null, isAuthenticated: boolean, result: T) {
    this.token = token;
    this.isAuthenticated = isAuthenticated;
    this.fetchResult = result;
    this.fetchResultList = [];
  }

  public getDataLoadingStatus() {
    return this.dataLoadingStatus;
  }

  private setDataLoadingStatus(status: DataLoadingStatus) {
    this.dataLoadingStatus = status;
  }

  public getErrorMsg() {
    return this.errorMsg;
  }

  private setErrorMsg(err: string) {
    this.errorMsg = err;
  }

  public getFetchResult() {
    return this.fetchResult;
  }

  public setFetchResult(result: T) {
    this.fetchResult = result;
  }

  public getFetchResultList() {
    return this.fetchResultList;
  }

  public async callApiCore(
    uri: string,
    apiMethod: string,
    responseKey?: string,
    requestBody?: object
  ) {
    if (this.isAuthenticated || isDevDesk()) {
      this.setDataLoadingStatus(DataLoadingStatus.LOADING);

      const invokeUrl = getApiInvokeUrl() + uri;
      const requestOptions: any = {
        headers: {
          'x-lambda-authorizer-token': this.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        method: apiMethod,
      };

      try {
        const response = await fetch(invokeUrl, requestOptions);

        //throw new Error("test error")

        let message = '';

        if (response.status !== 204) {
          response.ok
            ? this.setDataLoadingStatus(DataLoadingStatus.SUCCESS)
            : this.setDataLoadingStatus(DataLoadingStatus.FAILED);
          const result = await response.json();

          if (responseKey) {
            const fetchedValue = result[responseKey];
            this.setFetchResult(fetchedValue);
            this.fetchResultList.push(fetchedValue);
          }

          if (!response.ok) {
            this.setErrorMsg(result['message']);
            message = result['message'];
          }
        } else {
          this.setDataLoadingStatus(DataLoadingStatus.SUCCESS);
        }

        return {
          status: response.status,
          message: message,
          fetchResult: this.fetchResult,
        };
      } catch (err) {
        this.setDataLoadingStatus(DataLoadingStatus.FAILED);
        console.log('Error with FETCH', err as Error);
        this.setErrorMsg((err as Error).message);
      }
    }
  }
}
