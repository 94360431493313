import { CognitoAuth } from 'amazon-cognito-auth-ts';
import { authConfig } from './config';
import { PermissionsGroups } from '../interfaces';

const getAuth = () => {
  const auth = new CognitoAuth(authConfig());
  auth.useCodeGrantFlow();
  return auth;
};

function removeQueryFromLocation() {
  if (window.history.length > 0) {
    const newHref = window.location.href.split('?')[0];
    window.history.replaceState(undefined, 'Circe', newHref);
  }
}

function redirectToOriginalHash() {
  if (sessionStorage.getItem('startingUrlHash')) {
    const originalHash = sessionStorage.getItem('startingUrlHash');
    window.location.replace(
      `${window.location.href.substring(0, window.location.href.indexOf('#'))}${
        originalHash ?? ''
      }`
    );
  }
}
const nextRefresh = () =>
  new Date(+new Date() + refreshDelay).toLocaleTimeString();
const refreshDelay = 1000 * 60 * 45; // 1000ms = 1s, 60s = 1m, 45m
const ensureAuthenticated = (): Promise<CognitoAuth> =>
  new Promise((resolve, reject) => {
    const auth = getAuth();
    auth.userhandler = {
      onFailure: (err) => {
        auth.clearCachedTokensScopes();
        removeQueryFromLocation();
        reject(err);
      },
      onSuccess: (session) => {
        removeQueryFromLocation();
        resolve(auth);
        setInterval(async () => {
          console.info('Refreshing auth token');
          const ses = await auth.refreshSession(
            session.refreshToken.refreshToken
          );
          if (ses.isValid())
            console.info(
              'Auth token refreshed. Next refresh at',
              nextRefresh()
            );
          else
            console.error(
              'Auth token failed to refresh. Current state:',
              ses.state
            );
        }, refreshDelay);
        console.info(
          'Authenticated with Cognito and will refresh token at',
          nextRefresh()
        );
      },
    };

    const { href } = window.location;
    let session;
    try {
      session = auth.getSignInUserSession();
    } catch (ex) {
      auth.clearCachedTokensScopes();
      session = auth.getSignInUserSession();
    }

    if (session.isValid()) {
      resolve(auth);
    } else if (
      href.indexOf('?') > 0 &&
      href.substring(href.indexOf('?') + 1, href.indexOf('?') + 5) === 'code'
    ) {
      void auth.parseCognitoWebResponse(href);
    } else {
      void auth.getSession();
    }
  });
type AuthIdentity = {
  userId?: string;
};
type IdentitiesPayload = {
  identities?: AuthIdentity[];
  'custom:Groups': string;
};
export async function performUserAuthentication() {
  try {
    const response = await ensureAuthenticated();
    const session = response.getSignInUserSession();
    const idToken = session.getIdToken();
    const payload = idToken.decodePayload() as IdentitiesPayload;
    const identity =
      payload.identities && payload.identities.length > 0
        ? payload.identities[0]
        : null;
    const userAlias = identity ? identity['userId'] : null;
    const token = idToken.getJwtToken();
    const userGroups =
      payload && Object.keys(payload).length
        ? (JSON.parse(payload['custom:Groups']) as string[])
        : [];
    let packageInfoPermissionsGroup: PermissionsGroups;

    if (userGroups.includes('info-editor')) {
      packageInfoPermissionsGroup = 'info-editor';
    } else {
      packageInfoPermissionsGroup = 'readonly-user';
    }

    redirectToOriginalHash();

    return {
      isAuthenticated: !!token,
      token,
      userAlias,
      userGroups,
      packageInfoPermissionsGroup,
    };
  } catch (error) {
    console.log('there was an authentication error');
    console.log(error);
    return { error };
  }
}
