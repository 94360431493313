import { ReactNode } from 'react';
import { BadgeProps } from '@amzn/awsui-components-react';

export interface NodeChildren {
  children: ReactNode;
}

export interface FetchedLaunchData {
  data: LaunchTask[];
  loading: boolean;
}

export interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}

export interface Breadcrumb {
  text: string;
  href: string;
}

export interface LaunchTask {
  launchId: string;
  conference?: string;
  venue?: string;
  status?: string;
  launchDate?: number;
  dueDate?: number;
  lastTimeToBuild?: number;
  techDocsNeeded?: boolean;
  productName?: string;
  type?: string;
  scope?: string;
  codeName?: string;
  name: string;
  productManager?: string;
  marketingLead?: string;
  workStatus?: WorkStatus;
  writerAssignment: UserInfo;
  parentTask?: string;
  childTask?: string[];
  packageBuildInfoList?: PackageBuildInfo[];
  buildRequestId?: string;
  buildRequestStatus?: BuildRequestStatus;
  createdAt?: number;
  modifiedAt?: number;
  createdBy?: string;
  modifiedBy?: string;
  overdue?: boolean;
  versionSet?: string;
  packageBuildInfoModifiedAt?: number;
  editor?: UserInfo;
  serviceId?: string;
  reviewEndpoint?: string;
  documentationStatus?: DocumentationStatus;
  documentationNotes?: string;
  targetDocLiveTime?: number;
  allowedActions?: ActionList;
}

export type Action = 'READ' | 'WRITE';

type ActionList = Action[];

export interface UserInfo {
  alias: string;
  fullName: string;
}

export interface PackageBuildInfo {
  packageType: PackageType;
  packageName: string;
  commitId: string;
  branch: string;
  majorVersion: string;
}

export interface BuildRequestInfo {
  buildRequestId: string;
  buildRequestStatus: BuildRequestStatus;
  buildSubmitError: string;
  workStatus: WorkStatus;
}

export enum PackageType {
  API_REFERENCE = 'API_REFERENCE',
  ZONBOOK_GUIDE = 'ZONBOOK_GUIDE',
  SERVICE_LANDING_PAGE = 'SERVICE_LANDING_PAGE',
  SERVICE_SHARED_CONTENT = 'SERVICE_SHARED_CONTENT',
  DOC_MODEL = 'DOC_MODEL',
  SERVICE_MODEL = 'SERVICE_MODEL',
  EXTERNAL_MODEL = 'EXTERNAL_MODEL',
  CHECKLIST = 'CHECKLIST',
  STANDALONE_HELP_PANEL = 'STANDALONE_HELP_PANEL',
  CLOUDFORMATION_DOC_MODEL = 'CLOUDFORMATION_DOC_MODEL',
}

export enum WorkStatus {
  TASK_RECEIVED = 'TASK_RECEIVED',
  PACKAGE_SUBMITTED_BY_WRITER = 'PACKAGE_SUBMITTED_BY_WRITER',
  PACKAGE_BUILT = 'PACKAGE_BUILT',
  MANIFEST_BUILT = 'MANIFEST_BUILT',
  PACKAGE_BUILD_FAILED = 'PACKAGE_BUILD_FAILED',
  REVIEW_APPROVED = 'REVIEW_APPROVED',
  REVIEW_REJECTED = 'REVIEW_REJECTED',
  PUBLISH_SCHEDULED = 'PUBLISH_SCHEDULED',
  VENUE_CHANGED = 'VENUE_CHANGED',
  DELETED = 'DELETED',
  RESOLVED = 'RESOLVED',
}

export const workStatusMessages: Record<
  WorkStatus,
  { text: string; color: BadgeProps['color'] }
> = {
  TASK_RECEIVED: { text: 'Task Received', color: 'grey' },
  PACKAGE_SUBMITTED_BY_WRITER: {
    text: 'Package Information Updated',
    color: 'blue',
  },
  PACKAGE_BUILT: { text: 'Package Build Request Succeeded', color: 'green' },
  PACKAGE_BUILD_FAILED: { text: 'Package Build Request Failed', color: 'red' },
  MANIFEST_BUILT: { text: 'Manifest Built', color: 'blue' },
  REVIEW_APPROVED: { text: 'Ready to Publish', color: 'green' },
  REVIEW_REJECTED: { text: 'Update Package Information', color: 'red' },
  PUBLISH_SCHEDULED: { text: 'Publishing Scheduled', color: 'green' },
  DELETED: { text: 'Deleted', color: 'grey' },
  VENUE_CHANGED: { text: 'Venue Changed', color: 'green' },
  RESOLVED: { text: 'Resolved', color: 'green' },
} as const;

export enum DocumentationStatus {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
}

export enum TranslatedWorkStatus {
  TASK_RECEIVED = 'Task Received',
  PACKAGE_SUBMITTED_BY_WRITER = 'Package Information Updated',
  PACKAGE_BUILT = 'Package Build Request Succeeded',
  MANIFEST_BUILT = 'Manifest Built',
  PACKAGE_BUILD_FAILED = 'Package Build Request Failed',
  REVIEW_APPROVED = 'Ready to Publish',
  REVIEW_REJECTED = 'Update Package Information',
  PUBLISH_SCHEDULED = 'Publishing Scheduled',
  VENUE_CHANGED = 'Venue Changed',
  DELETED = 'Deleted',
  RESOLVED = 'Resolved',
}

export enum TraslatedDocumentStatus {
  GREEN = 'Green',
  YELLOW = 'Yellow',
  RED = 'Red',
}

export enum BuildRequestStatus {
  SUBMITTING = 'SUBMITTING',
  SUBMIT_FAILED = 'SUBMIT_FAILED',
  WAITING = 'WAITING',
  PRUNABLE = 'PRUNABLE',
  PENDING = 'PENDING',
  DEFERRED = 'DEFERRED',
  BLOCKED = 'BLOCKED',
  IN_PROGRESS = 'IN_PROGRESS',
  BLOCKED_ON_SERIALIZATION = 'BLOCKED_ON_SERIALIZATION',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  DONE = 'DONE',
  UNKNOWN = 'UNKNOWN',
  SUBMIT_SKIPPED = 'SUBMIT_SKIPPED',
}

export const buildStatusMessages: Record<BuildRequestStatus, string> = {
  SUBMITTING: '⚡ Submitting your build...',
  SUBMIT_FAILED: '🚨 Failed to submit build',
  WAITING: '⚡ Waiting...',
  PRUNABLE: '🤌 Your build can be pruned',
  PENDING: '⚡ Build in progress...',
  DEFERRED: '🚧 Build deferred',
  BLOCKED: '⛔ Build blocked!',
  IN_PROGRESS: '⚡ Build in progress...',
  BLOCKED_ON_SERIALIZATION: '⛔ Build blocked!',
  CANCELED: '🛑 Build cancelled',
  FAILED: '🔥 Build failed 🔥',
  DONE: 'Build succeeded! 🎉',
  UNKNOWN: '🤷 Awaiting status...',
  SUBMIT_SKIPPED: 'Updated 🎉',
} as const;

export type PermissionsGroups = 'readonly-user' | 'info-editor';

export type AutoSuggestPackageNameInput = {
  hint: string;
};

export type AutoSuggestPackageNameOutput = {
  suggestions: PackageNameList;
};

export type AutoSuggestStatus = 'loading' | 'finished' | 'error' | 'pending';
export type PackageNameList = string[];
