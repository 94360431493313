import { performUserAuthentication } from './utils';
import { useAuthDispatch } from './context';
import { useEffect } from 'react';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';

/**
 * Purpose of this component is to render a loading screen then perform
 * Cognito authentication
 */
export const AuthSpinner = () => {
  const dispatch = useAuthDispatch();

  /**
   * Have to use a workaround when using async functions in useEffect hooks
   * https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
   */
  useEffect(() => {
    void (async () => {
      const payload = await performUserAuthentication();
      // TODO: add error handling
      if (!payload.error) {
        dispatch({ type: 'LOGIN', payload });
      }
    })();
  }, []);
  return <Spinner size='large' />;
};
