import { createContext, ReactNode, useContext, useReducer } from 'react';
import { NodeChildren, PermissionsGroups } from '../interfaces';

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  userAlias: null,
  token: null,
  userGroups: [],
  error: null,
  packageInfoPermissionsGroup: null,
};

interface AuthState {
  isLoading: boolean;
  isAuthenticated: boolean;
  userAlias: string | null;
  token: string | null;
  userGroups: string[] | null;
  error: Object | null;
  packageInfoPermissionsGroup: PermissionsGroups | null;
}

interface AuthAction {
  type: 'LOGIN';
  payload: Object;
}

const AuthReducer = (
  initialState: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...initialState,
        ...action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

type Dispatch = (action: AuthAction) => void;

const AuthStateContext = createContext<AuthState | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

/**
 * Returns the current authentication state
 */
export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

/**
 * Returns a dispatcher to perform updates to the authentication state
 */
export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}

/**
 * Component for provider authentication state and dispatcher
 * to child components
 */
export const AuthProvider = ({ children }: NodeChildren) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
