import React from 'react';
import { Alert, Box, Link } from '@amzn/awsui-components-react';
import { Slackbox } from './Slackbox';

export const ZonbookWarning = () => (
  <Alert
    statusIconAriaLabel='Warning'
    type='warning'
    // buttonText='Acknowledge'
    header={'Have a checklist package?'}
  >
    <Box variant='p'>
      If your guide has a Checklist package, also specify it here. Otherwise,
      your build will fail.
    </Box>
    <Slackbox />
  </Alert>
);
