import { Box, SpaceBetween, Link } from '@amzn/awsui-components-react';

const Home = () => {
  return (
    <SpaceBetween direction={'vertical'} size={'s'}>
      <Box
        variant='h1'
        padding={{ top: 'xl' }}
        fontSize='heading-xl'
        color='inherit'
      >
        Welcome
      </Box>
    </SpaceBetween>
  );
};

export default Home;
