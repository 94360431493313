import { useState, useEffect } from 'react';
import SideNavigation, {
  SideNavigationProps,
} from '@amzn/awsui-components-react/polaris/side-navigation';
import { useLocation } from 'react-router';
import { getSideNavActiveHref } from './utils';

import Item = SideNavigationProps.Item;

const items: Item[] = [
  {
    type: 'section',
    text: 'TCX Launch Coordinator',
    items: [{ type: 'link', text: 'Launch List', href: '#/conferences' }],
  },
];

const SideNav = () => {
  const [activeHref, setActiveHref] = useState(window.location.hash);

  // Update the activeHref as the url changes
  const location = useLocation();
  useEffect(() => {
    setActiveHref(getSideNavActiveHref(location.pathname));
  }, [location.pathname]);

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '#/', text: 'Virtual Smiley' }}
      onFollow={(event) => {
        setActiveHref(event.detail.href);
      }}
      items={items}
    />
  );
};

export default SideNav;
