import { Input, InputProps } from '@amzn/awsui-components-react';
import { useEffect, useState, RefObject } from 'react';

type Props = InputProps & {
  regex?: RegExp | false;
  ariaRequired?: boolean;
  inputRef?: RefObject<HTMLInputElement>; // Add inputRef prop
};

export const Inputty = ({
  value,
  regex,
  ariaRequired = false,
  onChange,
  ...etc
}: Props) => {
  // Current value
  const [val, setVal] = useState(value);

  // Is a regex specified but the input doesn't match?
  // Is the input empty (only if ariaRequired is true)?
  const [wrong, setWrong] = useState(false);

  // Update the input's content if a new value is passed via props
  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    // Validate if regex is set
    setWrong(
      !etc.disabled &&
        Boolean(
          (regex && !regex.test(val)) || (ariaRequired && val?.trim() === '')
        )
    );
  }, [val]);

  return (
    <Input
      value={val}
      onChange={(d) => {
        // Update current value
        setVal(d.detail.value);
        onChange?.(d);
      }}
      invalid={wrong}
      {...etc}
    />
  );
};
