import { Box, Link } from '@amzn/awsui-components-react';
import React from 'react';

export const Slackbox = () => (
  <Box variant='p'>
    For help, view the{' '}
    <Link
      target='_blank'
      href={
        'https://w.amazon.com/bin/view/AWS_TCX/TCXConferences/ConferenceWriterSteps/#HAPIRefModelBuilds'
      }
    >
      TCX Conferences wiki
    </Link>{' '}
    or ask in the the{' '}
    <Link
      target='_blank'
      href='https://amzn-aws.slack.com/archives/G01B6BT0NF8'
    >
      #tcx-conferences Slack channel
    </Link>
    .
  </Box>
);
