import { CookieStorage } from 'amazon-cognito-auth-ts';
import { alphaEndpoint, integEndpoint } from './endpoint';
import { CognitoAuthOptions } from 'amazon-cognito-auth-ts/lib/CognitoAuth';

const devLocalPort = 8000;
const localUrl = `http://localhost:${devLocalPort}`;

export const tokenScope: string[] = ['openid'];

const envEndpoints = {
  alpha: alphaEndpoint,
  integ: integEndpoint,
} as const;

type Env = keyof typeof envEndpoints;

export const authConfig = (): CognitoAuthOptions => {
  const redirectUrl = getRedirectUrl();
  const env = getEnvironment();

  return {
    AppWebDomain: envEndpoints[env].appDomain,
    ClientId: envEndpoints[env].devClientId,
    RedirectUriSignIn: redirectUrl,
    RedirectUriSignOut: redirectUrl,
    TokenScopesArray: tokenScope,
    UserPoolId: envEndpoints[env].devEndpointUserPoolId,
    Storage: new CookieStorage(),
  };
};

const alphaHosts = {
  public: 'alpha.conference.virtualsmiley.docs.aws.a2z.com',
};

const integHosts = {
  public: 'integ.conference.virtualsmiley.docs.aws.a2z.com',
};

const getRedirectUrl = () => {
  const { host } = window.location;
  const https = 'https://';

  if (host.includes('alpha')) return https + alphaHosts.public;

  for (const env of [alphaHosts.public, integHosts.public])
    if (host.includes(env)) return https + env;

  return localUrl;
};

export const getEnvironment = (): Env => {
  const [subdomain] = window.location.host.split('.');
  return subdomain in envEndpoints ? (subdomain as Env) : 'alpha';
};
