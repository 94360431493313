import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Box from '@amzn/awsui-components-react/polaris/box';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Input from '@amzn/awsui-components-react/polaris/input';
import Button from '@amzn/awsui-components-react/polaris/button';
import { useEffect, useState } from 'react';

export type PublishConfirmerProps = {
  onConfirm: () => void;
  onDismiss: () => void;
  visible: boolean;
  launchId: string;
};
export const PublishConfirmer = ({
  onConfirm,
  onDismiss,
  visible,
  launchId,
}: PublishConfirmerProps) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    if (visible) setValue('');
  }, [visible]);
  return (
    <Modal
      onDismiss={onDismiss}
      visible={visible}
      size={'large'}
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={onDismiss}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={onConfirm}
              disabled={value.trim() !== 'confirm'}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={<React.Fragment>Confirm publish of {launchId}</React.Fragment>}
    >
      <SpaceBetween size='m'>
        <Box variant='p'>
          Are you sure you want to mark the launch with ID{' '}
          <Box variant='pre' display='inline-block'>
            {launchId}
          </Box>{' '}
          as 'Ready to Publish'?
        </Box>
        <Alert statusIconAriaLabel='Caution' type='warning'>
          Proceeding with this action will mark this launch item as ‘Ready to
          Deploy’. Please proceed only if you have verified the documentation on
          the endpoint. You will not be able to undo this action or update any
          information once you proceed.
        </Alert>
        To avoid accidental action, we ask you to provide additional written
        consent.
        <Box variant='p'>
          <strong>Type confirm this action, type “confirm”.</strong>
          <Input
            value={value}
            onChange={(e) => setValue(e.detail.value)}
            ariaRequired
            autoFocus
            disableBrowserAutocorrect
            placeholder='confirm'
          />
        </Box>
      </SpaceBetween>
    </Modal>
  );
};
