import { Box, Link } from '@amzn/awsui-components-react';

export const BuildNotice = ({ buildId }: { buildId: string }) => (
  <Box variant={'p'}>
    You can track your build here:{' '}
    <Link target='_blank' href={`https://build.amazon.com/${buildId}`}>
      build.amazon.com/{buildId}
    </Link>
  </Box>
);
