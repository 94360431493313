import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Container,
  FormField,
  ColumnLayout,
  Input,
  Textarea,
} from '@amzn/awsui-components-react/polaris';
import { useState, useEffect } from 'react';
import { LaunchTask, WorkStatus } from '../../../interfaces';

interface editorViewProps {
  launchTaskDetail: LaunchTask; // suppose to have length limitation, I don't know the exact number yet
}

export const EditorView = ({ launchTaskDetail }: editorViewProps) => {
  const [documentationStatusNotesValue, setDocumentationStatusNotesValue] =
    useState(launchTaskDetail.documentationNotes);

  return (
    <Container
      header={
        <Header
          actions={
            <Button
              variant='primary'
              disabled={launchTaskDetail.workStatus === WorkStatus.DELETED}
              onClick={() => {
                location.href = `/#/conferences/view-${launchTaskDetail.launchId.toString()}/editor`;
              }}
            >
              Edit
            </Button>
          }
        >
          Assigned editor
        </Header>
      }
    >
      <ColumnLayout columns={2}>
        <div className='editorViewColumn1'>
          <FormField label={'Editor'}></FormField>
          <Input
            disabled={true}
            value={launchTaskDetail.editor?.alias ?? '-'}
          />
        </div>
      </ColumnLayout>
    </Container>
  );
};

export default EditorView;
