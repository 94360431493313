import { ProtoPBI } from './ProtoPBI';
import {
  packageNameRegex,
  branchNameRegex,
  commitIdRegex,
} from '../../../../patterns';

export const validatePackages = (items: ProtoPBI[]) =>
  items.every(
    (item) =>
      item.packageType !== '' &&
      packageNameRegex.test(item.packageName.trim()) &&
      branchNameRegex.test(item.branch.trim()) &&
      commitIdRegex.test(item.commitId.trim())
  );
