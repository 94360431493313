import { Autosuggest, AutosuggestProps } from '@amzn/awsui-components-react';
import debounce from 'debounce-promise';
import { Dispatch, useEffect, useState } from 'react';
import { useAuthState } from '../authentication/context';
import { getApiInvokeUrl } from '../ConferencePages/conferenceInfo/utils';
import { hapi } from '../ConferencePages/conferenceInfo/hapi';
import {
  AutoSuggestPackageNameOutput,
  AutoSuggestStatus,
  PackageNameList,
} from '../interfaces';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { BaseChangeDetail } from '@amzn/awsui-components-react/polaris/input/interfaces';
import { Autobot } from '../Autobot';
import { packageNameRegex } from '../patterns';

type Props = {
  ariaRequired?: boolean;
  disabled?: boolean;
  // Input label for accessibility
  ariaLabel?: string;

  // A package name to preload
  packageName?: string;

  // Callback function when a package name has been entered
  onChange?: NonCancelableEventHandler<BaseChangeDetail>;
};
const callPackageSuggester = async (
  token: string,
  hint: string,
  setStatus?: Dispatch<AutoSuggestStatus>
): Promise<PackageNameList> => {
  setStatus?.('loading');
  const res = await hapi('/packages/nameSuggestions', token, 'POST', {
    hint,
  }).then((res: AutoSuggestPackageNameOutput) => res.suggestions);
  setStatus?.('finished');
  return res;
};
const debouncedApi = debounce(callPackageSuggester, 1000);
const packageNameToOption = (name: string) => ({ value: name, label: name });
export const PackageSuggester = ({
  ariaRequired = false,
  disabled = false,
  ariaLabel,
  packageName,
  onChange,
}: Props) => {
  // Store last hint queried to avoid redundant queries
  const [lastHint, setLastHint] = useState('bananaboat9001');

  const { isAuthenticated, token } = useAuthState();
  const [availablePackagesStatus, setAvailablePackagesStatus] =
    useState<AutoSuggestStatus>('finished');
  const [availablePackagesList, setAvailablePackagesList] =
    useState<PackageNameList>([]);
  const [availablePackagesOptions, setAvailablePackagesOptions] =
    useState<AutosuggestProps.Options>([]);

  const getAvailablePackages = async (): Promise<void> => {
    if (
      // Hint must be set and at least 3 characters
      !packageName ||
      packageName.length < 3
    )
      return setAvailablePackagesOptions([]);

    if (
      // Fetch must not be disabled
      availablePackagesStatus === 'error' ||
      // Must be authenticated or running on a dev desktop
      !isAuthenticated
    )
      return;
    try {
      if (!token) return;
      const availablePackages = await debouncedApi(
        token,
        packageName || '',
        setAvailablePackagesStatus
      );
      setLastHint(packageName);
      setAvailablePackagesList(availablePackages);
    } catch (err) {
      setAvailablePackagesStatus('error');
      console.log('error with FETCH', err);
    }
  };
  useEffect((): void => {
    if (availablePackagesList.length > 0) {
      const filteredPackagesOptions = availablePackagesList;
      const options: AutosuggestProps.Options =
        filteredPackagesOptions?.length > 0
          ? filteredPackagesOptions.sort().map(packageNameToOption)
          : [];
      setAvailablePackagesOptions(options);
    }
  }, [availablePackagesList]);

  return (
    <Autobot
      regex={packageNameRegex}
      ariaRequired={ariaRequired}
      disabled={disabled}
      onKeyUp={() => getAvailablePackages()}
      onChange={onChange}
      value={packageName ?? ''}
      options={availablePackagesOptions}
      enteredTextLabel={(value) => `Use: "${value}"`}
      ariaLabel={ariaLabel}
      placeholder='Search for a package name'
      empty='No matches found'
      loadingText='Loading packages...'
      statusType={availablePackagesStatus}
      errorText={'Error retrieving packages.'}
      recoveryText={'Retry'}
    />
  );
};
