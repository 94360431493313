import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';

export const PublishNotice = (props: {
  visible: boolean;
  onDismiss: () => void;
  launchId: string;
}) => {
  return (
    <Modal
      onDismiss={props.onDismiss}
      visible={props.visible}
      size='large'
      footer={
        <Box float='right'>
          <Button variant='primary' onClick={props.onDismiss}>
            Done
          </Button>
        </Box>
      }
      header={<React.Fragment>Publish scheduled</React.Fragment>}
    >
      Launch <Box variant='code'>{props.launchId}</Box> has been scheduled for
      publishing
    </Modal>
  );
};
