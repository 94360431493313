import { EmptyStateProps } from '../../interfaces';
import { Box } from '@amzn/awsui-components-react';

export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign='center' color='inherit'>
      <Box variant='strong' textAlign='center' color='inherit'>
        {title}
      </Box>
      <Box variant='p' padding={{ bottom: 's' }} color='inherit'>
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}
