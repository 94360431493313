import { ConferenceListTable } from './ui/ConferenceTable';
import Error from '../Error';
import { useState, useEffect } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { TaskDetail } from '../../ConferencePages/conferenceInfo/ui/viewConference';
import { LaunchTask } from '../../interfaces';
import { useAuthState } from '../../authentication/context';

import { callApiClass, DataLoadingStatus } from './callApiFunctions/callApi';
import { EditBuddyLaunchPage } from '../conferenceInfo/ui/editBuddyLaunches';
import { EditorModificationView } from '../conferenceInfo/ui/editEditorViewSection';

import { Packages } from './ui/PackagesEditor/Packages';
import EditWriterSection from './ui/editWriterSection';
import AdvanceErrorHandlingPage from '../AdvancedErrorPage';

import { ModificationView } from '../conferenceInfo/ui/editPermissions';
import { PermissionsView } from './ui/PermissionsView';

export function ConferenceList() {
  const [LaunchData, setLaunchData] = useState<LaunchTask[]>([]);
  const [LaunchDataLoading, setLaunchDataLoading] = useState(false);
  const [FetchLaunchTaskListSuccess, setFetchLaunchTaskListSuccess] =
    useState(true);

  const { path } = useRouteMatch();
  const { isAuthenticated, token } = useAuthState();

  const sampleTaskListObj: LaunchTask[] = [];

  const location = useLocation();

  const callAPIObj = new callApiClass(
    token,
    isAuthenticated,
    sampleTaskListObj
  );

  const callGetLaunchTaskApiObj = new callApiClass(
    token,
    isAuthenticated,
    sampleTaskListObj[0]
  );

  const [launchTaskDetail, setLaunchTaskDetail] = useState(
    sampleTaskListObj[0]
  );

  const [detailViewLoadingStatus, setDetailViewLoadingStatus] = useState(true);
  const [detailViewFetchStatusCode, setDetailViewFetchStatusCode] =
    useState(-1);
  const [detailViewFetchSuccess, setDetailViewFetchSuccess] = useState(true);

  const [buddyLaunches, setBuddyLaunches] = useState<LaunchTask[]>([]);
  const [buddyLaunchPageLoadingStatus, setBuddyLaunchesPageLoadingStatus] =
    useState(true);
  const [buddyLaunchFetchSuccess, setBuddyLaunchFetchSuccess] = useState(true);

  const [errorMsg, setErrorMsg] = useState('');

  async function callListLaunchesApi() {
    await callAPIObj.callApiCore('/launches', 'GET', 'tasks');

    setLaunchDataLoading(false);
    if (callAPIObj.getDataLoadingStatus() === DataLoadingStatus.SUCCESS) {
      setFetchLaunchTaskListSuccess(true);
      setLaunchData(callAPIObj.getFetchResult());
    }

    if (callAPIObj.getDataLoadingStatus() === DataLoadingStatus.FAILED) {
      setFetchLaunchTaskListSuccess(false);
      setErrorMsg(callAPIObj.getErrorMsg());
    }
  }

  async function callGetLaunchApi(id: string) {
    const response = await callGetLaunchTaskApiObj.callApiCore(
      `/launches/${id}`,
      'GET',
      'task'
    );

    setDetailViewLoadingStatus(false);
    setErrorMsg(callGetLaunchTaskApiObj.getErrorMsg());

    response
      ? setDetailViewFetchStatusCode(response.status)
      : setDetailViewFetchStatusCode(0);

    if (
      callGetLaunchTaskApiObj.getDataLoadingStatus() ===
      DataLoadingStatus.FAILED
    ) {
      setDetailViewFetchSuccess(false);
    }

    if (
      callGetLaunchTaskApiObj.getDataLoadingStatus() ===
      DataLoadingStatus.SUCCESS
    ) {
      setLaunchTaskDetail(callGetLaunchTaskApiObj.getFetchResult());
      setDetailViewFetchSuccess(true);
      const children = callGetLaunchTaskApiObj.getFetchResult().childTask;
      if (children) {
        const currentLocation = location.pathname.split('/');
        if (
          currentLocation.length === 3 ||
          (currentLocation.length === 4 &&
            currentLocation[3] === 'buddylaunches')
        )
          callGetBuddyLaunchApi(children);
      } else {
        setBuddyLaunches([]);
        setBuddyLaunchesPageLoadingStatus(false);
        setBuddyLaunchFetchSuccess(true);
      }
    }
  }

  const callGetBuddyLaunchApiObj = new callApiClass(
    token,
    isAuthenticated,
    sampleTaskListObj[0]
  );

  async function callGetBuddyLaunchApi(idList: string[]) {
    setBuddyLaunchesPageLoadingStatus(true);

    const apiPromises = idList.map((id) =>
      callGetBuddyLaunchApiObj.callApiCore(`/launches/${id}`, 'GET', 'task')
    );
    const apiResponses = await Promise.all(apiPromises);

    const allSuccessful = apiResponses.every(
      (response) => response && response.status === 200
    );

    setBuddyLaunchFetchSuccess(allSuccessful);
    setBuddyLaunchesPageLoadingStatus(false);
    if (allSuccessful) {
      setBuddyLaunches(callGetBuddyLaunchApiObj.getFetchResultList());
    }
  }

  useEffect(() => {
    console.log('pAtHnAmE', location.pathname);
    //if (isServiceList && isAuthenticated && fetchData) {
    const currentLocation = location.pathname.split('/');
    //currentLocaiton == 2 means page is at launch list page
    if (isAuthenticated) {
      if (currentLocation.length === 2) {
        setLaunchDataLoading(true);
        callListLaunchesApi();
      }

      if (currentLocation.length === 3 || currentLocation.length === 4) {
        setDetailViewLoadingStatus(true);
        console.log('id', currentLocation[2].substring(5));
        callGetLaunchApi(currentLocation[2].substring(5));
      }
    }
  }, [isAuthenticated, location.pathname]);

  return (
    <div>
      <Switch>
        <Route
          exact
          path={path}
          render={() =>
            FetchLaunchTaskListSuccess ? (
              <ConferenceListTable
                data={LaunchData}
                loading={LaunchDataLoading}
              />
            ) : (
              <AdvanceErrorHandlingPage errorMsg={errorMsg} />
            )
          }
        />
        <Route
          path={`${path}/view-:id/editor`}
          render={() =>
            detailViewFetchSuccess ? (
              <EditorModificationView launchTaskDetail={launchTaskDetail} />
            ) : (
              <AdvanceErrorHandlingPage
                status={detailViewFetchStatusCode}
                errorMsg={errorMsg}
              />
            )
          }
        />
        <Route
          path={`${path}/view-:id/writer`}
          render={() =>
            detailViewFetchSuccess ? (
              <EditWriterSection task={launchTaskDetail} />
            ) : (
              <AdvanceErrorHandlingPage
                status={detailViewFetchStatusCode}
                errorMsg={errorMsg}
              />
            )
          }
        />
        <Route
          path={`${path}/view-:id/buddylaunches`}
          render={() => {
            return (
              <EditBuddyLaunchPage
                launchTaskDetail={launchTaskDetail}
                buddyLaunches={buddyLaunches}
                buddyLaunchesLoading={buddyLaunchPageLoadingStatus}
                buddyLaunchFetchSuccess={buddyLaunchFetchSuccess}
              />
            );
          }}
        />

        <Route
          path={`${path}/view-:id/packages`}
          render={() => {
            if (launchTaskDetail) {
              return <Packages task={launchTaskDetail} editable={true} />;
            } else {
              return <Error />;
            }
          }}
        />

        <Route
          path={`${path}/view-:id/permissions`}
          render={({ match }) =>
            detailViewFetchSuccess ? (
              <>
                <SpaceBetween size='m' direction='vertical'>
                  <ModificationView launchTaskDetail={launchTaskDetail} />
                  <PermissionsView id={match.params.id} />
                </SpaceBetween>
              </>
            ) : (
              <AdvanceErrorHandlingPage
                status={detailViewFetchStatusCode}
                errorMsg={errorMsg}
              />
            )
          }
        />

        <Route
          path={`${path}/view-:id`}
          render={({ match }) => {
            const id = match.params.id;
            return (
              <TaskDetail
                id={id}
                launchTask={launchTaskDetail}
                detailLoading={detailViewLoadingStatus}
                detailFetchStatus={detailViewFetchStatusCode}
                errorMsg={errorMsg}
                buddyLaunchesLoading={buddyLaunchPageLoadingStatus}
                buddyLaunches={buddyLaunches}
                buddyLaunchFetchSuccess={buddyLaunchFetchSuccess}
              />
            );
          }}
        />

        <Route render={() => <Error />} />
      </Switch>
    </div>
  );
}

export default ConferenceList;
