import { useState, useEffect } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import BreadcrumbGroup from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import SideNav from '../components/navigation/SideNav';
import AppRouter from './AppRouter';
import { useLocation } from 'react-router';
import { useNavDispatch, useNavState } from '../components/navigation/context';
import { useAuthState } from '../authentication/context';
import { isDevDesk } from '../ConferencePages/conferenceInfo/utils';

export default function App() {
  const [navigationOpen, setNavigationOpen] = useState(true);
  const { breadcrumbTrail, breadcrumbNameOverrides } = useNavState();
  const { isAuthenticated } = useAuthState();
  const dispatch = useNavDispatch();
  const location = useLocation();

  // Update the breadcrumb trail as the url or breadcrumb overrides change
  // Breadcrumbs are only shown after a user is authenticated
  useEffect(() => {
    if (isAuthenticated || isDevDesk()) {
      dispatch({ type: 'NAVIGATE_TO', pathname: location.pathname });
    }
  }, [location.pathname]);

  if (window.location.hash !== '#/' && window.location.hash !== '') {
    sessionStorage.setItem('startingUrlHash', window.location.hash);
  }

  return (
    <AppLayout
      content={<AppRouter />}
      contentType='default'
      navigation={<SideNav />}
      breadcrumbs={<BreadcrumbGroup items={breadcrumbTrail} />}
      toolsHide
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
    />
  );
}
