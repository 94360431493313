import {
  LaunchTask,
  TranslatedWorkStatus,
  WorkStatus,
} from '../../../interfaces/index';
import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  SpaceBetween,
  Container,
  FormField,
  Input,
  Spinner,
  Textarea,
  Flashbar,
  FlashbarProps,
  Select,
} from '@amzn/awsui-components-react/polaris';

import { v4 } from 'uuid';

import {
  DocumentationStatus,
  TraslatedDocumentStatus,
} from '../../../interfaces';
import { callApiClass } from '../callApiFunctions/callApi';

import { useAuthState } from '../../../authentication/context';

interface editEditorProps {
  launchTaskDetail: LaunchTask;
}

export function generateFlashBarItem(id: string) {
  const item: FlashbarProps.MessageDefinition = {
    dismissible: true,
    dismissLabel: 'Dismiss message',
    onDismiss: void Function,

    id: id,
  };

  return item;
}

export const EditorModificationView = ({
  launchTaskDetail,
}: editEditorProps) => {
  const [editor, setEditor] = useState(launchTaskDetail?.editor?.alias);

  const [editorErrorText, setEditorErrorText] = useState('');

  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [callUpdateEditorAPIResult, setCallUpdateEditorAPIResult] =
    useState(true);

  const [editorLoading, setEditorLoading] = useState(true);

  const [displayFlashBarEditor, setDisplayFlashBarEditor] = useState(false);

  const [fetchErrorMsg, setFetchErrorMsg] = useState('');

  const id2 = v4();
  const item2 = generateFlashBarItem(id2);
  item2.onDismiss = () =>
    setEditorItems((items) => items.filter((item) => item.id !== id2));
  item2.type = 'success';
  item2.content = 'updating editor';
  item2.loading = true;

  const [editorItems, setEditorItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([item2]);

  useEffect(() => {
    if (editor) {
      const allLetters = /^[A-Za-z0-9]+$/;
      const invalidChars = /[@#$%^&*]/;
      if (!allLetters.exec(editor) || invalidChars.exec(editor)) {
        setEditorErrorText('Alias Can only take letters or number');
      } else if (editor.includes(' ')) {
        setEditorErrorText('Alias Cannot Have Space in Between');
      } else {
        setEditorErrorText('');
      }
    }
  }, [editor]);

  useEffect(() => {
    setEditor(launchTaskDetail?.editor?.alias);
  }, [launchTaskDetail]);

  useEffect(() => {
    editorErrorText === ''
      ? setDisableSaveButton(false)
      : setDisableSaveButton(true);
  }, [editorErrorText]);

  useEffect(() => {
    const newEditorItems = [...editorItems];

    if (!editorLoading) {
      if (callUpdateEditorAPIResult) {
        item2.content = 'update editors success';
      } else {
        item2.type = 'error';
        item2.content = fetchErrorMsg;
      }
      item2.loading = false;
    }
    newEditorItems[0] = item2;

    setEditorItems(newEditorItems);
  }, [callUpdateEditorAPIResult, editorLoading, fetchErrorMsg]);

  const { isAuthenticated, token, packageInfoPermissionsGroup } =
    useAuthState();

  const callUpdateEditorAPIObj = new callApiClass(token, isAuthenticated, {});

  async function callUpdateEditorAPI() {
    const result = await callUpdateEditorAPIObj.callApiCore(
      `/launches/${launchTaskDetail?.launchId}/editor`,
      'PATCH',
      'message',
      {
        editor: {
          alias: editor,
        },
      }
    );
    setEditorLoading(false);
    if (result !== undefined && Math.floor(result.status / 100) === 2) {
      setCallUpdateEditorAPIResult(true);
    } else {
      setCallUpdateEditorAPIResult(false);
      if (result) {
        setFetchErrorMsg(callUpdateEditorAPIObj.getFetchResult().toString());
      }
    }
  }

  if (launchTaskDetail) {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <SpaceBetween direction='vertical' size='s'>
          <Box display={displayFlashBarEditor ? 'block' : 'none'}>
            <Flashbar items={editorItems} />
          </Box>

          <Container>
            <SpaceBetween direction='vertical' size='xl'>
              <Container>
                <SpaceBetween direction='vertical' size='l'>
                  <FormField
                    label='Editor'
                    errorText={editorErrorText}
                  ></FormField>
                  <Input
                    value={editor ?? ''}
                    onChange={({ detail }) => {
                      setEditor(detail.value);
                    }}
                  />
                  <SpaceBetween direction='horizontal' size='l'>
                    <Button
                      variant='primary'
                      disabled={
                        disableSaveButton ||
                        launchTaskDetail?.workStatus === WorkStatus.DELETED
                      }
                      onClick={() => {
                        setEditorLoading(true);
                        callUpdateEditorAPI();
                        setDisplayFlashBarEditor(true);
                      }}
                    >
                      Save Editors
                    </Button>
                  </SpaceBetween>
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </form>
    );
  }

  return (
    <div>
      <Spinner size='large' />
      Loading...
    </div>
  );
};

export default EditorModificationView;
