import React from 'react';
import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Spinner,
  Flashbar,
  FlashbarProps,
} from '@amzn/awsui-components-react/polaris';
import { v4 } from 'uuid';
import { generateFlashBarItem } from './conferenceInfo/ui/editEditorViewSection';

interface advanceErrorHandlingProps {
  status?: number;
  errorMsg: string;
}

const id = v4();
const errorItem: FlashbarProps.MessageDefinition = generateFlashBarItem(id);

errorItem.loading = false;
errorItem.type = 'error';

export const AdvanceErrorHandlingPage = ({
  status,
  errorMsg,
}: advanceErrorHandlingProps) => {
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  useEffect(() => {
    const newItems = [];

    errorItem.action = (
      <Button variant='normal' href='#/'>
        Home page
      </Button>
    );

    errorItem.onDismiss = () => {
      setItems([...items].filter((item) => item.id !== id));
    };
    errorItem.header = `error: ${status ? status : 'unknown'}`;

    errorMsg
      ? (errorItem.content = `${errorMsg}`)
      : (errorItem.content =
          'Page cannot be found, there may be a connectivity issue, please try again later');

    newItems.push(errorItem);
    setItems(newItems);
  }, [status, errorMsg]);

  return (
    <div>
      <Flashbar items={items} />
    </div>
  );
};

export default AdvanceErrorHandlingPage;
