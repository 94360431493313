import { getApiInvokeUrl, isDevDesk } from './utils';

type Method = 'GET' | 'PUT' | 'PATCH' | 'POST';
export const hapi = async (
  uri: string,
  token: string,
  method: Method = 'GET',
  requestBody?: object
) =>
  fetch(getApiInvokeUrl() + uri, {
    headers: {
      'x-lambda-authorizer-token': token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
    method,
  })
    .then(async (response) => {
      if (!response.ok) {
        const json = await response.json();
        const message = 'message' in json ? json.message : JSON.stringify(json);
        throw new Error(`Error ${response.status}: ${message}`);
      }

      return response.status === 204 ? '' : response.json();
    })
    .then((res) => {
      if (typeof res === 'undefined') throw new Error(res);
      return res;
    });
export const lessHapi = async (
  uri: string,
  token: string,
  options?: { method: Method; requestBody?: object }
) =>
  fetch(getApiInvokeUrl() + uri, {
    headers: {
      'x-lambda-authorizer-token': token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options?.requestBody),
    method: options?.method,
  })
    .then(async (response) => {
      if (!response.ok) {
        const json = await response.json();
        const message = 'message' in json ? json.message : JSON.stringify(json);
        throw new Error(`Error ${response.status}: ${message}`);
      }

      return response.status === 204 ? '' : response.text();
    })
    .then((res) => {
      if (typeof res === 'undefined') throw new Error(res);
      return res;
    });
