import React from 'react';
import { Alert, Box, Link } from '@amzn/awsui-components-react';
import { Slackbox } from './Slackbox';

export const ReferenceWarning = () => (
  <Alert
    statusIconAriaLabel='Warning'
    type='warning'
    // buttonText='Acknowledge'
    header={'API doc build process'}
  >
    <Box variant='p'>
      Before you submit your API doc build for the first time, you must build
      your model packages to the AWSDevDocsInteg/conference-service-models
      version set.
    </Box>
    <ol>
      <li key={1}>
        Go to{' '}
        <Link target='_blank' href={'https://build.amazon.com'}>
          build.amazon.com
        </Link>
        .
      </li>
      <li key={2}>
        Enter AWSDevDocsInteg/conference-service-models in the version set.
      </li>
      <li key={3}>
        Add doc model, service model, and external model / C2j packages (NOT
        YOUR API DOC PACKAGE) to AWSDevDocsInteg/conference-service-models.
      </li>
      <li key={4}>Wait for the build to complete.</li>
      <li key={5}>
        Bookmark the build request to use as a starting point if you need to
        update the API models later.
      </li>
      <li key={6}>
        Return to TLC and submit the packages. Note that you will only specify
        your API doc package in TLC, not your serviceModel, docModel,
        externalModel (or C2J) packages.
      </li>
    </ol>
    <Box variant={'p'}>
      To change the model packages later, repeat steps 1-4 to build the models
      into the AWSDevDocsInteg/conference-service-models version set. After the
      build completes, the API docs will automatically rebuild to show the
      updated versions on the conference endpoint.
    </Box>
    <Slackbox />
  </Alert>
);
