import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris';

type PermissionsContextType = {
  readPermissions: string[];
  writePermissions: string[];
  notifications: FlashbarProps.MessageDefinition[];
  setNotifications: Dispatch<
    React.SetStateAction<FlashbarProps.MessageDefinition[]>
  >;
  updateReadPermissions: Dispatch<SetStateAction<string[]>>;
  updateWritePermissions: Dispatch<SetStateAction<string[]>>;
};

const PermissionsContext = createContext<PermissionsContextType>({
  readPermissions: [],
  writePermissions: [],
  notifications: [],
  setNotifications: () => {},

  updateReadPermissions: () => {},
  updateWritePermissions: () => {},
});

export const PermissionsProvider: React.FC = ({ children }) => {
  const [readPermissions, setReadPermissions] = useState<string[]>([]);
  const [writePermissions, setWritePermissions] = useState<string[]>([]);
  const [notifications, setNotifications] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const updateReadPermissions: PermissionsContextType['updateReadPermissions'] =
    (newPermissions) => {
      setReadPermissions(newPermissions);
    };

  const updateWritePermissions: PermissionsContextType['updateWritePermissions'] =
    (newPermissions) => {
      setWritePermissions(newPermissions);
    };

  return (
    <PermissionsContext.Provider
      value={{
        readPermissions,
        writePermissions,
        notifications,
        updateReadPermissions,
        updateWritePermissions,
        setNotifications,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export { PermissionsContext };
