interface Endpoint {
  devClientId: string;
  devEndpointUserPoolId: string;
  appDomain: string;
}

export const alphaEndpoint: Endpoint = {
  devClientId: '50mc4fgpb7p4qngcm0j1aajf2s',
  devEndpointUserPoolId: 'us-east-1_lowRBOh2D',
  appDomain: 'alpha-conferencemanager.auth.us-east-1.amazoncognito.com',
};

export const integEndpoint: Endpoint = {
  devClientId: '2bubu35du01etqnlcmn4cmh3aj',
  devEndpointUserPoolId: 'us-east-1_zcUgrjZQj',
  appDomain: 'integ-conferencemanager.auth.us-east-1.amazoncognito.com',
};
