import React from 'react';
import {
  Alert,
  Box,
  Button,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
export function ErrorGoToHomepage() {
  return (
    <Box padding='xl'>
      <Spinner size='large' />
    </Box>
  );
}
