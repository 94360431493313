import { PackageSuggester } from '../../../../components/PackageSuggester';
import { PackageTypeSelector } from './PackageTypeSelector';
import { Inputty } from '../../../../Inputty';
import { Box, Button, FormField, Grid } from '@amzn/awsui-components-react';
import styles from './PackageForm.module.sass';
import { ProtoPBI } from './ProtoPBI';
import { ListUpdater } from './ListUpdater';
import { branchNameRegex, commitIdRegex } from '../../../../patterns';
import commitStyles from '../commitId.module.sass';

export type PackageFormProps = {
  item: ProtoPBI;
  editing: boolean;
  update: ReturnType<ListUpdater>;
  remove: () => void;
  validate: boolean;
};
export const PackageForm = ({
  editing,
  item,
  update,
  validate,
  remove,
}: PackageFormProps) => (
  <Box>
    <Grid
      className={styles.package}
      gridDefinition={
        editing
          ? [
              { colspan: 3 },
              { colspan: 2 },
              { colspan: 3 },
              { colspan: 3 },
              { colspan: 1 },
            ]
          : [{ colspan: 3 }, { colspan: 2 }, { colspan: 3 }, { colspan: 4 }]
      }
    >
      <div>
        <FormField description='Name of the package' label='Package name'>
          <PackageSuggester
            disabled={!editing}
            ariaRequired={true}
            packageName={item.packageName}
            onChange={update('packageName')}
            // regex={packageNameRegex}
          />
        </FormField>
      </div>
      <FormField description='Select package type' label='Package type'>
        <PackageTypeSelector
          editing={editing}
          item={item}
          onChange={update('packageType')}
        />
      </FormField>
      <div>
        <FormField description='Select branch for the package' label='Branch'>
          <Inputty
            disabled={!editing}
            ariaRequired={true}
            value={item.branch ? item.branch : ''}
            onChange={update('branch')}
            regex={branchNameRegex}
          />
        </FormField>
      </div>
      <div className={commitStyles.commitId}>
        <FormField
          description='The full 40-character commit hash'
          label='Commit ID'
        >
          <Inputty
            disabled={!editing}
            ariaRequired={true}
            value={item.commitId}
            onChange={update('commitId')}
            regex={validate && commitIdRegex}
          />
        </FormField>
      </div>
      {editing && (
        <Button
          disabled={!editing}
          iconName={'close'}
          variant='icon'
          ariaLabel={'Remove'}
          onClick={remove}
        />
      )}
    </Grid>
  </Box>
);
