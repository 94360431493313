import * as React from 'react';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import { useEffect } from 'react';

export const ErrorBar = ({
  errors,
}: {
  errors: {
    header: string;
    content: string;
    type?: 'success' | 'warning' | 'info' | 'error' | 'in-progress';
  }[];
}) => {
  const [items, setItems] = React.useState(errors);
  useEffect(() => {}, [errors]);
  return (
    <Flashbar
      items={items.map(({ header, content, type }, i) => ({
        header,
        type: type ?? 'error',
        content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () =>
          setItems([...items.slice(0, i), ...items.slice(i + 1)]),
        id: 'message_1',
      }))}
    />
  );
};
