import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Container,
  FormField,
  Autosuggest,
  Grid,
  Input,
  Textarea,
  ColumnLayout,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react/polaris';
import React, { useState, useEffect } from 'react';
import { LaunchTask } from '../../../interfaces/index';
import { convertToPST } from '../utils';

interface detailSectionProps {
  launchTask: LaunchTask;
  id: string;
  loading: boolean;
}

export const DetailSection = ({ launchTask, loading }: detailSectionProps) => {
  //const { isAuthenticated, token, packageInfoPermissionsGroup } = useAuthState();
  const [dataLoading, setDataLoading] = useState(true);

  const [launchTaskDetail, setLaunchTaskDetail] =
    useState<LaunchTask>(launchTask);

  useEffect(() => {
    setLaunchTaskDetail(launchTask);
    setDataLoading(loading);
  }, [launchTask]);

  if (loading) {
    return (
      <Container>
        <Spinner size='large' />
      </Container>
    );
  }

  return (
    <SpaceBetween direction='vertical' size='l'>
      <Container>
        <Grid
          gridDefinition={[
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 6 },
            { colspan: 6 },
          ]}
        >
          <div className='column1'>
            <SpaceBetween direction='vertical' size='xxl'>
              <FormField
                // description='This is a description.'
                label='Launch ID'
              >
                <Input
                  readOnly={true}
                  disabled={true}
                  value={
                    launchTaskDetail?.launchId ? launchTaskDetail.launchId : '-'
                  }
                />
              </FormField>
              <FormField label='Launch Name'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={launchTaskDetail?.name ? launchTaskDetail.name : '-'}
                />
              </FormField>
            </SpaceBetween>
          </div>

          <div className='column2'>
            <SpaceBetween direction='vertical' size='xxl'>
              <FormField label='Launch Conference'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={
                    launchTaskDetail?.conference
                      ? launchTaskDetail.conference
                      : '-'
                  }
                />
              </FormField>
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <FormField label='Launch-Type'>
                  <Input
                    readOnly={true}
                    disabled={true}
                    value={launchTaskDetail?.type ? launchTaskDetail.type : '-'}
                  />
                </FormField>
                <FormField label='Scope'>
                  <Input
                    readOnly={true}
                    disabled={true}
                    value={
                      launchTaskDetail?.scope ? launchTaskDetail.scope : '-'
                    }
                  />
                </FormField>
              </Grid>
            </SpaceBetween>
          </div>

          <div className='column3'>
            <SpaceBetween direction='vertical' size='xxl'>
              <FormField label='Launch Venue'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={launchTaskDetail?.venue ? launchTaskDetail.venue : '-'}
                />
              </FormField>
              <FormField label='Code Name'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={
                    launchTaskDetail?.codeName ? launchTaskDetail.codeName : '-'
                  }
                />
              </FormField>
            </SpaceBetween>
          </div>

          <div className='column4'>
            <SpaceBetween direction='vertical' size='xxl'>
              <FormField label='Product Name'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={
                    launchTaskDetail?.productName
                      ? launchTaskDetail.productName
                      : '-'
                  }
                />
              </FormField>
              <FormField label='Product Manager'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={
                    launchTaskDetail?.productManager
                      ? launchTaskDetail.productManager
                      : '-'
                  }
                />
              </FormField>
            </SpaceBetween>
          </div>

          <div className='column5'>
            <SpaceBetween direction='vertical' size='xxl'>
              <FormField label='Launch Date'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={
                    launchTaskDetail?.launchDate
                      ? convertToPST(launchTaskDetail.launchDate)
                      : '-'
                  }
                />
              </FormField>
              <FormField label='Marketing Lead'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={
                    launchTaskDetail?.marketingLead
                      ? launchTaskDetail.marketingLead
                      : '-'
                  }
                />
              </FormField>
            </SpaceBetween>
          </div>

          <div className='column6'>
            <SpaceBetween direction='vertical' size='xxl'>
              <FormField label='Launch Status'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={
                    launchTaskDetail?.status ? launchTaskDetail.status : '-'
                  }
                />
              </FormField>
              <FormField label='Tech Docs Needed'>
                <Input
                  readOnly={true}
                  disabled={true}
                  value={launchTaskDetail?.techDocsNeeded ? 'Yes' : 'No'}
                />
              </FormField>
            </SpaceBetween>
          </div>

          <FormField label='Last Build Time'>
            <Input
              value={
                launchTask?.lastTimeToBuild
                  ? convertToPST(launchTask?.lastTimeToBuild, true)
                  : '-'
              }
              readOnly={true}
              disabled={true}
            />
          </FormField>
          <FormField label='Target Live Time'>
            <Input
              value={
                launchTask?.targetDocLiveTime
                  ? convertToPST(launchTask?.targetDocLiveTime, true)
                  : '-'
              }
              readOnly={true}
              disabled={true}
            />
          </FormField>
        </Grid>
      </Container>
    </SpaceBetween>
  );
};

export default DetailSection;
